import type { ServerNotifyMessagePayload } from '#core/types/packages/socket'
import type { SettingsPack } from '#settings-pack/types'
import { BOARD_CACHE, ModelListCache } from '#core/cache'
import { SETTINGS_PACK_LOADER_FRAGMENT } from '#settings-pack/loader_fragment'
import { SETTINGS_PACK_TYPE_NAME } from '#settings-pack/constant'

class SettingsPackListCache extends ModelListCache<SettingsPack> {
  constructor() {
    super(SETTINGS_PACK_TYPE_NAME)
  }

  override getBoardCacheKeys() {
    return [{
      key: BOARD_CACHE.DATA,
      subKey: 'settingsPacks',
    }]
  }
}

export const useSettingsPackSync = () => {
  const { client } = useApolloClient()
  const { currentBoard } = useWorkspaceSharedState()
  const listCache = new SettingsPackListCache()
  const { getSettingsPackIdentifier } = useSettingsPackDataConvert()

  const onAdd = async (payload: Partial<SettingsPack>) => {
    client.cache.writeFragment({
      data: payload,
      fragment: SETTINGS_PACK_LOADER_FRAGMENT,
      fragmentName: 'SettingsPackLoader',
    })

    listCache.add(currentBoard.value.id, payload)
  }

  const onDelete = (payload: Partial<SettingsPack>) => {
    client.cache.evict({
      id: getSettingsPackIdentifier(payload.id as string),
    })
  }

  const sync = (payload: ServerNotifyMessagePayload) => {
    const eventRegister = new Map<
      ServerNotifyMessagePayload['action'],
      (payload: Partial<SettingsPack>) => void
        >([
          ['ADD', onAdd],
          ['DELETE', onDelete],
        ])

    const eventHandler = eventRegister.get(payload.action)
    eventHandler?.(payload.model)
  }

  return {
    sync,
    listCache,
  }
}
