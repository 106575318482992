import { pick } from 'lodash-es'
import { SETTINGS_PACK_FRAGMENT } from '#settings-pack/fragment'
import { SETTINGS_PACK_TYPE_NAME } from '#settings-pack/constant'
import type { BaseSettingsPack, SettingsPack, SettingsPackSyncPayload } from '#settings-pack/types'
import { getIdentifier, createFieldModifiers } from '#core/utils/apollo'
import type { FieldsModifier } from '#core/types'

export const useSettingsPackDataConvert = () => {
  const { client } = useApolloClient()
  const getSettingsPackIdentifier = (id: string) => getIdentifier(id, SETTINGS_PACK_TYPE_NAME)
  const fields = ['workspaceId', 'boards', 'taskTypes', 'statuses', 'labels']
  const defaultSettingsPack = {
    id: '',
    name: '',
    description: '',
    boards: [],
    taskTypes: [],
    statuses: [],
    labels: [],
  }

  const getSettingsPackObject = (id: string) => (
    client.readFragment<SettingsPack>({
      id,
      fragment: SETTINGS_PACK_FRAGMENT,
      fragmentName: 'SettingsPack',
    })
  )

  const getOptimisticSettingsPack = (id: string | null, payload: Partial<BaseSettingsPack>, forFields = false): Partial<BaseSettingsPack> & { __typename?: string } => {
    const defaults = { boards: [], taskTypes: [], statuses: [], labels: [] }
    const settingsPack = getOptimisticObject(id, payload, fields, defaults, forFields)

    return forFields ? settingsPack : { ...settingsPack, __typename: SETTINGS_PACK_TYPE_NAME }
  }

  const getOptimisticSettingsPackFields = (payload: Partial<BaseSettingsPack>) => {
    const settingsPack = getOptimisticSettingsPack(null, payload, true)
    return convertObjectToFields(settingsPack)
  }

  const getSyncSettingsPackFields = (payload: SettingsPackSyncPayload) => {
    const fieldModifiers = createFieldModifiers(fields, payload)
    return pick(fieldModifiers, Object.keys(payload)) as FieldsModifier<BaseSettingsPack>
  }

  return {
    getSettingsPackIdentifier,
    getOptimisticSettingsPack,
    getOptimisticSettingsPackFields,
    getSyncSettingsPackFields,
    getSettingsPackObject,
    defaultSettingsPack,
  }
}
